exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-filter-js": () => import("./../../../src/pages/filter.js" /* webpackChunkName: "component---src-pages-filter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-js": () => import("./../../../src/pages/pl.js" /* webpackChunkName: "component---src-pages-pl-js" */),
  "component---src-pages-pl-search-js": () => import("./../../../src/pages/pl/search.js" /* webpackChunkName: "component---src-pages-pl-search-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/blog-page.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-categories-recipe-jsx": () => import("./../../../src/templates/categories-recipe.jsx" /* webpackChunkName: "component---src-templates-categories-recipe-jsx" */),
  "component---src-templates-singe-recipe-jsx": () => import("./../../../src/templates/singe-recipe.jsx" /* webpackChunkName: "component---src-templates-singe-recipe-jsx" */),
  "component---src-templates-what-to-cook-blog-jsx": () => import("./../../../src/templates/what_to_cook_blog.jsx" /* webpackChunkName: "component---src-templates-what-to-cook-blog-jsx" */)
}

